<template>
  <div>
    <chosenListPanel :list.sync="list" @select="toSelectCommunity"></chosenListPanel>
    <multi-select2
        title="手机号列表"
        :isShow.sync="visible"
        :searchParams="searchParams"
        searchMethod="POST"
        v-bind="multiParams"
        :backFill.sync="list"
        :appendToBody="true"
        :maxCount="2000"
        @callback="onSelected">
          <template #searchSlot>
            <v-textarea label="手机号" v-model="searchParams.mobileNum" placeholder="手机号集合以英文逗号隔开,'如18022221111,13955556666',数据返回最多2000条" :maxlength="24000"></v-textarea>
            <v-select label="房号类型" v-model="searchParams.roomType" :options="roomTypeOps"></v-select>
          </template>
      </multi-select2>
  </div>
</template>
<script>
import { MultiSelect2, ChosenListPanel } from 'components/bussiness'
import { getOwnerPhoneListURL } from './api'
import { roomTypeOps } from './map'
export default {
  name: 'UserChosenByMobilePanel',
  props: ['value', 'appCode'],
  components: {
    'multi-select2': MultiSelect2,
    ChosenListPanel
  },
  data () {
    return {
      visible: false,
      searchParams: {
        mobileNum: '',
        appCode: '',
        roomType: 1
      },
      multiParams: {
        searchUrl: getOwnerPhoneListURL,
        headers: [
          {
            prop: 'userName',
            label: '姓名'
          },
          {
            prop: 'mobileNum',
            label: '手机号'
          },
          {
            prop: 'address',
            label: '项目住址'
          },
          {
            prop: 'regTime',
            label: '注册时间'
          }
        ],
        responseParams: {
          id: 'id',
          name: 'userName'
        },
        responseKey: {
          id: 'id',
          name: 'text'
        }
      },
      roomTypeOps,
      list: []
    }
  },
  methods: {
    toSelectCommunity () {
      this.searchParams.appCode = this.appCode
      this.visible = true
    },
    onSelected () {
      this.$emit('input', this.list)
    }
  },
  watch: {
    'value': function (newValue) {
      this.list = newValue
    }
  }
}
</script>
