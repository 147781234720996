<template>
  <div class="BackstageNoticeForm-wrapper">
    <form-panel
      ref="noticeForm"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
    >
      <template #headerSlot>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <col2-detail :span="24">
        <col2-block title="通知信息">
          <el-form-item
            label="通知标题"
            :rules="[
              { required: true, message: '请输入通知标题', trigger: 'blur' },
            ]"
            prop="noticeTitle"
          >
            <v-input
              v-model="form.noticeTitle"
              placeholder="标题字数最多30字"
              :width="width"
            />
          </el-form-item>
          <el-form-item
            label="通知概要"
            :rules="[
              { required: true, message: '通知概要不能为空', trigger: 'blur' },
            ]"
            prop="noticeSummary"
          >
            <v-textarea
              v-model="form.noticeSummary"
              :maxlength="textareaMaxLen"
              placeholder
            ></v-textarea>
          </el-form-item>
          <el-form-item label="通知封面图片">
            <v-uploader
              :action="uploadURL"
              :fileHeight="300"
              :fileWidth="630"
              :imgUrls.sync="form.imgUrl"
            >
              <template #tip>
                <span>注：建议宽630px高300px，图片小于512k</span>
              </template>
            </v-uploader>
          </el-form-item>
          <el-form-item
            label="发布类型"
            :rules="[{ required: true, message: '', trigger: 'change' }]"
            prop="appType"
          >
            <!-- 新增商业通知 -->
            <span v-if="noticeType === 5">用户端App</span>
            <!-- 新增商家通知 -->
            <span v-else-if="noticeType === 23">商家端App</span>
            <!-- 新增项目、网格通知 -->
            <v-select
              v-model="form.appType"
              v-else
              :options="gridTypeOps"
              :width="width"
              @change="appTypeChange"
            />
          </el-form-item>
          <el-form-item
            label="APP名称"
            prop="appCode"
            v-show="form.appType === 1"
          >
            <v-select2
              ref="select"
              v-model="form.appCode"
              v-bind="appCodeParams"
              placeholder="全部"
            />
          </el-form-item>
          <el-form-item
            label="跳转类型"
            prop="skipType"
            :rules="[
              { required: true, message: '请选择跳转类型', trigger: 'change' },
            ]"
          >
            <checkbox-plus :options="skipTypeOps" :value.sync="form.skipType" />
          </el-form-item>
          <div class="el-form-div-wrapper" v-if="form.skipType === 1">
            <el-form-item
              label="通知内容"
              prop="noticeContent"
              :rules="[
                {
                  required: true,
                  message: '输入内容不能为空',
                  trigger: 'change',
                },
              ]"
            >
              <v-ueditor v-model="form.noticeContent"></v-ueditor>
              <div class="choose-jump-wrapper" v-if="form.appType === 1">
                <jump-go @callback="jumpCallback"></jump-go>
              </div>
            </el-form-item>
          </div>
          <div v-if="form.skipType === 2">
            <el-form-item
              label="跳转页面"
              prop="skipData"
              :rules="[
                {
                  required: true,
                  message: '请选择跳转页面',
                  trigger: 'change',
                },
              ]"
            >
              <jump-go
                :btnText="jumpName"
                :skipType="2"
                @callback="jumpFunCallback"
              ></jump-go>
            </el-form-item>
          </div>
          <el-form-item
            label="外部链接"
            prop="url"
            v-if="form.skipType === 3"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                trigger: 'change',
              },
            ]"
          >
            <div class="content-wrapper">
              <v-input
                v-model="form.url"
                placeholder="外部链接URL地址"
                :width="width"
                :maxlength="200"
              ></v-input>
              <span class="right-tip">注：请填写以"http://"开头的URL地址</span>
            </div>
          </el-form-item>
          <el-form-item
            label="发布范围"
            prop="sendType"
            :rules="[
              { required: true, message: '请选择发布范围', trigger: 'change' },
            ]"
          >
            <checkbox-plus :options="sendTypeOps" :value.sync="form.sendType" />
          </el-form-item>
          <el-form-item
            v-if="
              form.skipType === 1 && noticeType !== 5 && form.sendType !== 3
            "
            label="信息签收"
            :rules="[
              { required: true, message: '请选择信息签收', trigger: 'change' },
            ]"
            prop="sign"
          >
            <v-select v-model="form.sign" :options="signOps" :width="width" />
          </el-form-item>
          <div v-if="form.sendType === 1 && form.appType === 1">
            <el-form-item
              label="发布对象"
              prop="receiveUserIds"
              :rules="[
                {
                  type: 'array',
                  required: true,
                  message: '当前选项不允许为空',
                  trigger: 'change',
                },
              ]"
            >
              <chosenListPanel
                key="receiveUserIds"
                :list.sync="form.receiveUserIds"
                @select="toSelectOwner"
              ></chosenListPanel>
            </el-form-item>
          </div>
          <div v-if="form.sendType === 'sameAs1' && form.appType === 1">
            <el-form-item
              label="发布对象"
              prop="receiveUserIds"
              :rules="[
                {
                  type: 'array',
                  required: true,
                  message: '当前选项不允许为空',
                  trigger: 'change',
                },
              ]"
            >
              <user-chosen-by-mobile-panel v-model="form.receiveUserIds" :appCode="form.appCode" />
            </el-form-item>
          </div>
          <div v-if="form.sendType === 1 && form.appType === 2">
            <el-form-item
              label="发布对象"
              prop="butlerWorkerIds"
              :rules="[
                {
                  type: 'array',
                  required: true,
                  message: '当前选项不允许为空',
                  trigger: 'change',
                },
              ]"
            >
              <chosenListPanel
                key="butlerWorkerIds"
                :list.sync="form.butlerWorkerIds"
                @select="toSelectbutlerWorker"
              ></chosenListPanel>
            </el-form-item>
          </div>
          <div v-if="form.sendType === 2 || form.sendType === 50">
            <el-form-item
              label="发布对象"
              prop="communityIDs"
              :rules="[
                {
                  type: 'array',
                  required: true,
                  message: '当前选项不允许为空',
                  trigger: 'change',
                },
              ]"
            >
              <chosenListPanel
                key="communityIDs"
                :list.sync="form.communityIDs"
                @select="toSelectcommunity"
              ></chosenListPanel>
            </el-form-item>
          </div>
          <div v-if="form.sendType === 4">
            <el-form-item
              label="发布对象"
              prop="nodeIds"
              :rules="[
                {
                  type: 'array',
                  required: true,
                  message: '当前选项不允许为空',
                  trigger: 'change',
                },
              ]"
            >
              <chosenListPanel
                key="nodeIds"
                :list.sync="form.nodeIds"
                @select="toSelectBuidding"
              ></chosenListPanel>
            </el-form-item>
          </div>
          <div v-if="form.sendType === 5">
            <el-form-item
              label="发布对象"
              prop="orgIds"
              :rules="[
                {
                  type: 'array',
                  required: true,
                  message: '当前选项不允许为空',
                  trigger: 'change',
                },
              ]"
            >
              <chosenListPanel
                key="orgIds"
                :list.sync="form.orgIds"
                @select="toSelectOrgIds"
              ></chosenListPanel>
            </el-form-item>
          </div>
          <div v-if="form.sendType === 6">
            <el-form-item
              label="发布对象"
              prop="superOrgIds"
              :rules="[
                {
                  type: 'array',
                  required: true,
                  message: '当前选项不允许为空',
                  trigger: 'change',
                },
              ]"
            >
              <chosenListPanel
                key="superOrgIds"
                :list.sync="form.superOrgIds"
                @select="toSelectSuperOrgIds"
              ></chosenListPanel>
            </el-form-item>
          </div>
          <div v-if="form.sendType === 7">
            <el-form-item
              label="发布对象"
              prop="listIds"
              :rules="[
                {
                  type: 'array',
                  required: true,
                  message: '当前选项不允许为空',
                  trigger: 'change',
                },
              ]"
            >
              <chosenListPanel
                key="listIds"
                :list.sync="form.listIds"
                @select="toSelectListIds"
              ></chosenListPanel>
            </el-form-item>
          </div>
          <div v-if="form.sendType === 8">
            <el-form-item
              label="发布对象"
              prop="shopListIds"
              :rules="[
                {
                  type: 'array',
                  required: true,
                  message: '当前选项不允许为空',
                  trigger: 'change',
                },
              ]"
            >
              <chosenListPanel
                key="shopListIds"
                :list.sync="form.shopListIds"
                @select="toSelectShopListIds"
              ></chosenListPanel>
            </el-form-item>
          </div>
          <div v-if="form.sendType === 9">
            <el-form-item
              label="发布对象"
              prop="storeListIds"
              :rules="[
                {
                  type: 'array',
                  required: true,
                  message: '当前选项不允许为空',
                  trigger: 'change',
                },
              ]"
            >
              <chosenListPanel
                key="storeListIds"
                :list.sync="form.storeListIds"
                @select="toSelectStoreListIds"
              ></chosenListPanel>
            </el-form-item>
          </div>
          <el-form-item
            label="立即发送"
            :rules="[
              { required: true, message: '请选择立即发送', trigger: 'change' },
            ]"
            prop="sendImmediately"
          >
            <v-select
              v-model="form.sendImmediately"
              :options="sendImmediatelyOps"
              :width="width"
            />
          </el-form-item>
          <el-form-item
            label="发布时间"
            prop="pushtime"
            v-if="form.sendImmediately === 0"
            :rules="[
              {
                required: true,
                message: '发布时间不允许为空',
                trigger: 'blur',
              },
            ]"
          >
            <div class="content-wrapper">
              <v-datepicker
                :width="width"
                :minuteStep="10"
                :minDate="minDate"
                v-model="form.pushtime"
                type="datetime"
                format="YYYY-MM-DD HH:mm"
              />
              <span class="right-tip">注：时间间隔可设为10分钟</span>
            </div>
          </el-form-item>
          <mobile-preview :isShow.sync="isPreviewShow" title="内容预览">
            <div class="preview-content" slot="content">
              <div class="preview-title">{{ form.noticeTitle }}</div>
              <div class="preview-time">{{ form.pushtime }}</div>
              <div v-html="form.noticeContent" class="preview-con"></div>
            </div>
          </mobile-preview>
        </col2-block>
        <multi-select
          title="业主列表"
          :key="key + 1"
          :isShow.sync="selectOwnerShow"
          :searchUrl="getOwnerListURL"
          :headers="ownerSelectTableHeader"
          :searchParams="ownerSearchParams"
          :responseParams="ownerResponseParams"
          :responseKey="ownerResponseKey"
          :extraParams="ownerExtraParams"
          :backFill.sync="form.receiveUserIds"
        >
          <template #searchSlot>
            <v-input
              label="姓名"
              v-model="ownerSearchParams.userName"
            ></v-input>
            <v-input
              label="手机号"
              v-model="ownerSearchParams.mobileNum"
            ></v-input>
            <v-select
              label="数据来源"
              v-model="ownerSearchParams.userSource"
              :options="userSourceOps"
            ></v-select>
            <v-select2
              label="所属项目"
              v-model="ownerSearchParams.communityId"
              v-bind="communityParams"
              :subjoin="subjoin"
            ></v-select2>
            <v-input label="苑" v-model="ownerSearchParams.address"></v-input>
            <v-input label="幢" v-model="ownerSearchParams.buildNum"></v-input>
            <v-input label="单元" v-model="ownerSearchParams.unitNum"></v-input>
            <v-input label="室" v-model="ownerSearchParams.roomNum"></v-input>
            <v-datepicker
              label="注册时间"
              type="rangedatetimer"
              :startTime.sync="ownerSearchParams.regFromDate"
              :endTime.sync="ownerSearchParams.regToDate"
              :maxDate="maxDate"
              :appendToBody="appendToBody"
            ></v-datepicker>
          </template>
        </multi-select>
        <multi-select
          title="物管人员列表"
          :key="key + 2"
          :isShow.sync="selectReceiveUserShow"
          :searchUrl="getReceiveUserListURL"
          :headers="receiveUserSelectTableHeader"
          :searchParams="receiveUserSearchParams"
          :responseParams="receiveUserResponseParams"
          :responseKey="receiveUserResponseKey"
          :backFill.sync="form.butlerWorkerIds"
          :extraParams="receiveUserExtraParams"
        >
          <template #searchSlot>
            <v-input
              label="姓名"
              v-model="receiveUserSearchParams.userName"
            ></v-input>
            <v-input
              label="登录名"
              v-model="receiveUserSearchParams.logingName"
            ></v-input>
            <v-input
              label="手机号"
              v-model="receiveUserSearchParams.phone"
            ></v-input>
            <v-select
              label="角色类型"
              v-model="receiveUserSearchParams.roleType"
              :options="roleTypeOps"
              @change="roleTypeChange"
            ></v-select>
            <v-select2
              label="角色名称"
              ref="roleName"
              v-model="receiveUserSearchParams.roleId"
              v-bind="roleNameParams"
            ></v-select2>
            <v-select2
              label="所属组织"
              ref="organizeName"
              v-model="receiveUserSearchParams.organizeId"
              v-bind="organizeParams"
            ></v-select2>
            <v-datepicker
              label="创建时间"
              type="rangedatetimer"
              :startTime.sync="receiveUserSearchParams.startIntime"
              :endTime.sync="receiveUserSearchParams.endIntime"
              :maxDate="maxDate"
              :appendToBody="appendToBody"
            ></v-datepicker>
          </template>
        </multi-select>
        <multi-select
          title="项目列表"
          :key="key + 3"
          :isShow.sync="selectCommunityShow"
          :searchUrl="getCommunityListURL"
          :headers="communitySelectTableHeader"
          :searchParams="communitySearchParams"
          :responseParams="communityResponseParams"
          :responseKey="communityResponseKey"
          :backFill.sync="form.communityIDs"
          :extraParams="communityExtraParams"
          @callback="communityIdChange"
        >
          <template #searchSlot>
            <v-input
              label="项目名称"
              v-model="communitySearchParams.communityName"
            ></v-input>
            <v-select
              label="区域类型"
              v-model="communitySearchParams.searchRegionType"
              :options="searchRegionTypeOps"
              @change="searchRegionTypeChange"
            ></v-select>
            <v-select2
              label="所属区域"
              ref="regionName"
              v-model="communitySearchParams.regionId"
              v-bind="regionIdParams"
              :subjoin="{ regionType: communitySearchParams.searchRegionType }"
            ></v-select2>
            <v-input
              label="所在省"
              v-model="communitySearchParams.province"
            ></v-input>
            <v-input
              label="所在城市"
              v-model="communitySearchParams.city"
            ></v-input>
            <v-input
              label="所在区"
              v-model="communitySearchParams.area"
            ></v-input>
            <v-select
              label="项目阶段状态"
              v-model="communitySearchParams.communityStage"
              :options="communityStageOps"
            ></v-select>
            <v-select
              label="项目类型"
              v-model="communitySearchParams.communityType"
              :options="communityTypeOps"
            ></v-select>
          </template>
        </multi-select>
        <multi-select
          title="选择楼幢"
          :key="key + 4"
          :isShow.sync="selectBuiddingShow"
          :searchUrl="getBuiddingListURL"
          :headers="buiddingSelectTableHeader"
          :searchParams="buiddingSearchParams"
          :responseParams="buiddingResponseParams"
          :responseKey="buiddingResponseKey"
          :backFill.sync="form.nodeIds"
          @callback="communityIdChange"
        >
          <template #searchSlot>
            <v-select2
              label="所属项目"
              v-model="buiddingSearchParams.communityId"
              v-bind="communityParams"
              :subjoin="subjoin"
            ></v-select2>
            <v-input
              label="所属组团"
              v-model="buiddingSearchParams.blockName"
            ></v-input>
            <v-input
              label="所属楼幢"
              v-model="buiddingSearchParams.buildingNumber"
            ></v-input>
          </template>
        </multi-select>
        <multi-select
          :title="'组织列表'"
          :key="key + 5"
          :isShow.sync="selectOrgIdsShow"
          :searchUrl="getOrgIdsListURL"
          :headers="orgIdsSelectTableHeader"
          :searchParams="orgIdsSearchParams"
          :responseParams="orgIdsResponseParams"
          :responseKey="orgIdsResponseKey"
          :backFill.sync="form.orgIds"
          :extraParams="orgIdsExtraParams"
        >
          <template #searchSlot>
            <v-input
              label="组织名称"
              v-model="orgIdsSearchParams.orgName"
            ></v-input>
            <v-select2
              label="关联项目"
              v-model="orgIdsSearchParams.communityId"
              v-bind="communityParams"
              :subjoin="subjoin"
            ></v-select2>
            <v-select2
              label="关联房号"
              v-model="orgIdsSearchParams.houseId"
              v-bind="houseIdParams"
              :subjoin="subjoin"
            ></v-select2>
            <v-select
              :label="`是否顶层${showOrgName}`"
              v-model="orgIdsSearchParams.isTop"
              :options="isTopOps"
            ></v-select>
            <v-select2
              :label="`${showOrgName}超管`"
              v-model="orgIdsSearchParams.managerId"
              v-bind="managerIdParams"
            ></v-select2>
            <v-select2
              :label="`顶层${showOrgName}`"
              v-model="orgIdsSearchParams.topId"
              v-bind="topIdParams"
            ></v-select2>
            <v-select
              label="数据来源"
              v-model="orgIdsSearchParams.source"
              :options="sourceOps"
            ></v-select>
          </template>
        </multi-select>
        <multi-select
          :key="key + 6"
          :title="`${showOrgName}超管列表`"
          :isShow.sync="selectSuperOrgIdsShow"
          :searchUrl="getOrgIdsListURL"
          :headers="orgIdsSelectTableHeader"
          :searchParams="orgIdsSearchParams"
          :responseParams="orgIdsResponseParams"
          :responseKey="orgIdsResponseKey"
          :backFill.sync="form.superOrgIds"
          :extraParams="orgIdsExtraParams"
        >
          <template #searchSlot>
            <v-input
              :label="`${showOrgName}名称`"
              v-model="orgIdsSearchParams.orgName"
            ></v-input>
            <v-select2
              label="关联项目"
              v-model="orgIdsSearchParams.communityId"
              v-bind="communityParams"
              :subjoin="subjoin"
            ></v-select2>
            <v-select2
              label="关联房号"
              v-model="orgIdsSearchParams.houseId"
              v-bind="houseIdParams"
              :subjoin="subjoin"
            ></v-select2>
            <v-select
              :label="`是否顶层${showOrgName}`"
              v-model="orgIdsSearchParams.isTop"
              :options="isTopOps"
            ></v-select>
            <v-select2
              :label="`${showOrgName}超管`"
              v-model="orgIdsSearchParams.managerId"
              v-bind="managerIdParams"
            ></v-select2>
            <v-select2
              :label="`顶层${showOrgName}`"
              v-model="orgIdsSearchParams.topId"
              v-bind="topIdParams"
            ></v-select2>
            <v-select
              label="数据来源"
              v-model="orgIdsSearchParams.source"
              :options="sourceOps"
            ></v-select>
          </template>
        </multi-select>
        <!-- 标签 -->
        <multi-select
          :key="key + 7"
          title="用户标签列表"
          :isShow.sync="selectListIdsShow"
          :searchUrl="getListIdsURL"
          :headers="listIdsSelectTableHeader"
          :searchParams="listIdsSearchParams"
          :responseParams="listIdsResponseParams"
          :responseKey="listIdsResponseKey"
          :backFill.sync="form.listIds"
          @callback="success"
        >
          <template #searchSlot>
            <v-input label="标签名称" v-model="listIdsSearchParams.labelName"></v-input>
            <v-select label="一级分类" asyncOptions @change="labelTypeChange" v-model="listIdsSearchParams.labelType" :options="labelStatusOpts"></v-select>
            <v-select label="二级分类" asyncOptions v-model="listIdsSearchParams.categoryId" :options="categoryOpts"></v-select>
            <v-input label="创建人" v-model="listIdsSearchParams.inuser"></v-input>
            <v-select label="状态" v-model="listIdsSearchParams.status" :options="statusOps"></v-select>
            <v-select label="标签层级" v-model="listIdsSearchParams.labelLevel" :options="labelLevelOps"></v-select>
            <v-datepicker
              label="创建时间"
              :startTime.sync="listIdsSearchParams.createStartTime"
              :endTime.sync="listIdsSearchParams.createEndTime"
              :appendToBody="appendToBody"
            ></v-datepicker>
            <v-datepicker
              label="最近用户加入时间"
              :startTime.sync="listIdsSearchParams.joinStartTime"
              :endTime.sync="listIdsSearchParams.joinEndTime"
              :appendToBody="appendToBody"
            ></v-datepicker>
          </template>
        </multi-select>
        <!-- 商家 -->
        <multi-select
          title="商家列表"
          :isShow.sync="selectShopListIdsShow"
          :searchUrl="getShopListURL"
          :headers="shopListIdsSelectTableHeader"
          :searchParams="shopListIdsSearchParams"
          :responseParams="shopListIdsResponseParams"
          :responseKey="shopListIdsResponseKey"
          :backFill.sync="form.shopListIds"
        >
          <template #searchSlot>
            <v-input label="商家名称" v-model="shopListIdsSearchParams.busName" placeholder="请输入商家名称"></v-input>
            <v-input label="商家账号" v-model="shopListIdsSearchParams.loginName" placeholder="请输入商家账号"></v-input>
            <v-select label="整合类型" v-model="shopListIdsSearchParams.integrationType" :options="integrationTypeOps"></v-select>
            <v-input label="整合方" v-model="shopListIdsSearchParams.integrationName" placeholder="请输入整合方名称"></v-input>
            <v-select2 label="收款单位" v-model="shopListIdsSearchParams.legalUnitId" v-bind="legalUnitIdParams"></v-select2>
            <v-select label="经营主体" v-model="shopListIdsSearchParams.isSelfsupport" :options="isSelfsupportOps"></v-select>
            <v-select label="是否支持售后" v-model="shopListIdsSearchParams.isSupportAftersales" :options="isSupportAftersalesOps"></v-select>
            <v-select label="商家状态" v-model="shopListIdsSearchParams.isOpen" :options="isOpenOps"></v-select>
            <v-datepicker label="添加时间" :maxDate="maxDate" :type="datepickerParams.type" :format="datepickerParams.format" :startTime.sync="shopListIdsSearchParams.startDate" :endTime.sync="shopListIdsSearchParams.endDate"></v-datepicker>
          </template>
        </multi-select>
        <!-- 门店 -->
        <multi-select
          title="门店列表"
          :isShow.sync="selectStoreListIdsShow"
          :searchUrl="getStoreListURL"
          :headers="storeListIdsSelectTableHeader"
          :searchParams="storeListIdsSearchParams"
          :responseParams="storeListIdsResponseParams"
          :responseKey="storeListIdsResponseKey"
          :backFill.sync="form.storeListIds"
        >
          <template #searchSlot>
            <v-input label="门店名称" v-model="storeListIdsSearchParams.storeName"></v-input>
            <v-select2 label="所属商家" v-model="storeListIdsSearchParams.busId" v-bind="busParams"></v-select2>
            <v-select label="服务范围" v-model="storeListIdsSearchParams.serviceScope" :options="serviceScopeOps"></v-select>
            <v-select label="物流方式" v-model="storeListIdsSearchParams.deliveryType" :options="deliveryTypeOps"></v-select>
            <v-select label="是否支持外赠" v-model="storeListIdsSearchParams.isGrant" :options="isGrantOps"></v-select>
            <v-input label="搜索标签" v-model="storeListIdsSearchParams.labelName"></v-input>
            <v-select label="门店状态" v-model="storeListIdsSearchParams.storeStatus" :options="storeStatusOps"></v-select>
          </template>
        </multi-select>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import {
  uploadURL,
  getOwnerListURL,
  getReceiveUserListURL,
  submitCreateFormURL,
  getRoleTypeURL,
  getCommunityListURL,
  getBuiddingListURL,
  getOrgIdsListURL,
  checkSkipCommunityURL,
  getRoleNameListURL,
  getOrganizeURL,
  geAllRegionURL,
  getCommunityStageURL,
  getCommunityTypeURL,
  getOrgTagURL,
  getUserRoomURL,
  superManagerURL,
  getTopOrgURL,
  APPURL,

  getListIdsURL,
  categoryOptionsURL,

  getShopListURL,
  getStoreListURL,

  getLegalUnitIdURl,
  getShopURL
} from './api'
import {
  appTypeOps,
  signOps,
  userSourceOps,
  userTypeMap,
  communityStatusMap,
  orgSourceMap,
  orgSourceOps,
  jumpGoCodeMap,
  searchRegionTypeOps,
  communityStatusOps,
  isTopOps,
  sourceOps,
  nodeLevelMap,
  sendImmediatelyOps,
  statusMap,
  labelLevelOps,
  statusOps,
  // sendTypeMapPermission
  //商家相关
  _integrationType,
  integrationTypeOps, 
  isSelfsupportOps, 
  isSupportAftersalesOps, 
  isOpenOps,

  //门店相关
  serviceScopeMap,
  deliveryTypeMap,
  storeStatusMap,
  serviceScopeOps,
  deliveryTypeOps,
  storeStatusOps,
  setIsGrantOps
} from './map'
import { vUploader, vUeditor } from 'components/control'
import {
  Col2Detail,
  Col2Block,
  CheckboxPlus,
  JumpGo,
  MultiSelect,
  ChosenListPanel,
  MobilePreview
} from 'components/bussiness'
import { createHTMLVNode } from 'common/vdom'
import { jumpGoCode } from 'components/bussiness/jump-go/code'
import { communityParams } from 'common/select2Params'
import UserChosenByMobilePanel from 'components/user-chosen-by-mobile-panel/user-chosen-by-mobile-panel'

export default {
  name: 'BackstageNoticeForm',
  components: {
    vUploader,
    Col2Detail,
    Col2Block,
    CheckboxPlus,
    vUeditor,
    JumpGo,
    MultiSelect,
    ChosenListPanel,
    MobilePreview,
    UserChosenByMobilePanel
  },
  data () {
    let ORG_NAME = this.$store.state.baseConfig.docConfig.ORG_NAME
    let _this = this
    return {
      statusOps,
      submitConfig: {
        submitUrl: submitCreateFormURL
      },
      appendToBody: false,
      maxDate: new Date(),
      noticeType: '',
      isPreviewShow: false,
      userType: undefined,
      textareaMaxLen: 50,
      width: 182,
      uploadURL,
      sendTypeMapPermission: new Map([
        ['个人', 'sendPersonal'],
        ['项目', 'sendCommunity'],
        ['全体用户', 'sendAll'],
        ['楼幢', 'sendBuilding'],
        [`${ORG_NAME}`, 'sendOrg'],
        [`${ORG_NAME}超管`, 'sendOrgSuperManager'],
        ['项目内企业管理员', 'sendBuildOrgManager'],
        ['手机号', 'sendPersonal'],
        ['用户标签', 'sendUserTag'],
        ['商家','sendMerchant'],
        ['门店','sendShop']
      ]),
      statusMap,
      appTypeOps: appTypeOps(),
      sendImmediatelyOps: sendImmediatelyOps(),
      orgSourceOps: orgSourceOps(1),
      searchRegionTypeOps: searchRegionTypeOps(1),
      communityStatusOps: communityStatusOps(1),
      isTopOps: isTopOps(1),
      sourceOps: sourceOps(1),
      communityStageOps: [],
      communityTypeOps: [],
      skipTypeOps: [
        {
          checked: true,
          label: '至详情页',
          value: 1
        },
        {
          checked: false,
          label: '至功能页',
          value: 2
        },
        {
          checked: false,
          label: '至外部链接',
          value: 3
        }
      ],
      sendTypeOps: [
        {
          checked: true,
          label: '项目',
          value: 2
        },
        {
          checked: false,
          label: '个人',
          value: 1
        },
        {
          checked: false,
          label: '全体用户',
          value: 3
        },
        {
          checked: false,
          label: '楼幢',
          value: 4
        },
        {
          checked: false,
          label: `${ORG_NAME}`,
          value: 5
        },
        {
          label: '手机号',
          value: 'sameAs1' // 类型值和个人相同，在提交数据时更改为1
        },
        {
          label: '项目内企业管理员',
          value: 50,
          checked: false
        }
      ],
      jumpName: '点击选择要跳转的页面',
      checkParam: {
        communityList: [],
        storeIdList: [],
        socialActivityIdList: [],
        secKillIdList: [],
        knowledgeInfoIdList: [],
        discountTopicId: [],
        groupIdList: [],
        productIdList: [],
        questionIdList: [],
        questionNaireIdList: [],
        selfRuleIdList: [],
        subIdList: [],
        tableIdList: [],
        workRoomIdList: []
      },
      checkParam2: {},
      signOps: signOps(2),
      selectOwnerShow: false,
      selectReceiveUserShow: false,
      selectCommunityShow: false,
      selectBuiddingShow: false,
      selectOrgIdsShow: false,
      selectSuperOrgIdsShow: false,
      selectListIdsShow: false,
      selectShopListIdsShow:false,
      selectStoreListIdsShow:false,
      ownerSelectTableHeader: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'address',
          label: '项目住址'
        },
        {
          prop: 'regTime',
          label: '注册时间'
        }
      ],
      receiveUserSelectTableHeader: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'loginName',
          label: '登录名'
        },
        {
          prop: 'phone',
          label: '手机号'
        },
        {
          prop: 'appType',
          label: '登录平台'
        },
        {
          prop: 'roleName',
          label: '角色名称'
        },
        {
          prop: 'userType',
          label: '角色类型',
          formatter: (row) => {
            return createHTMLVNode(this, userTypeMap[row.userType])
          }
        },
        {
          prop: 'orgName',
          label: '所属组织',
          formatter: (row) => {
            let text = row.orgName || '智慧园区一体化平台'
            return createHTMLVNode(this, text)
          }
        }
      ],
      communitySelectTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'communityStatus',
          label: '项目状态',
          formatter: (row) => {
            return createHTMLVNode(
              this,
              communityStatusMap[row.communityStatus]
            )
          }
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        {
          prop: 'provinceName',
          label: '所在省'
        },
        {
          prop: 'cityName',
          label: '所在市'
        },
        {
          prop: 'countryName',
          label: '所在区'
        },
        {
          prop: 'communityPhone',
          label: '项目电话'
        }
      ],
      buiddingSelectTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'displayName',
          label: '层级名称'
        },
        {
          prop: 'nodeLevel',
          label: '层级类型',
          formatter: (row) => {
            return createHTMLVNode(this, nodeLevelMap[row.nodeLevel])
          }
        }
      ],
      orgIdsSelectTableHeader: [
        {
          prop: 'orgName',
          label: `${ORG_NAME}名称`
        },
        {
          prop: 'tagName',
          label: `${ORG_NAME}标签`,
          formatter: (row) => {
            let tagName = row.tagName || []
            let text = tagName.join('<br>')
            return createHTMLVNode(this, text)
          }
        },
        {
          prop: 'communityName',
          label: '关联项目',
          formatter: (row) => {
            let communityName = row.communityName || []
            let text = communityName.join('<br>')
            return createHTMLVNode(this, text)
          }
        },
        {
          prop: 'houseName',
          label: '关联房号',
          formatter: (row) => {
            let houseName = row.houseName || []
            let text = houseName.join('<br>')
            return createHTMLVNode(this, text)
          }
        },
        {
          prop: 'managerName',
          label: `${ORG_NAME}超管`
        },
        {
          prop: 'top',
          label: `是否顶层${ORG_NAME}`
        },
        {
          prop: 'topParentName',
          label: `顶层${ORG_NAME}`
        },
        {
          prop: 'source',
          label: '数据来源',
          formatter: (row) => {
            return createHTMLVNode(this, orgSourceMap[row.source])
          }
        }
      ],
      listIdsSelectTableHeader: [
        {
          prop: 'labelName',
          label: '标签名称'
        },
        {
          prop: 'category',
          label: '分类'
        },
        {
          prop: 'relatedObjectCount',
          label: '关联对象数量',
          align: 'right'
        },
        {
          prop: 'status',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'labelLevel',
          label: '标签层级'
        },
        {
          prop: 'inuser',
          label: '创建人'
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'latestJoinTime',
          label: '最新用户加入时间'
        }
      ],
      shopListIdsSelectTableHeader:[
        {
          prop: 'busName',
          label: '商家名称',
        },
        {
          prop: 'loginName',
          label: '商家账号'
        },
        {
          prop: 'newIntegrationType',
          label: '整合类型',
          formatter (row) {
            return _integrationType[row.integrationType]
          }
        },
        {
          prop: 'integrationName',
          label: '整合方'
        },
        {
          prop: 'legalUnitName',
          label: '收款单位'
        },
        {
          prop: 'subPaytype',
          label: '支付方式',
          formatter (row) {
            return _this.$createElement('span', {
              domProps: {
                innerHTML: row.subPaytype
              }
            })
          }
        },
        {
          prop: 'isSelfsupport',
          label: '经营主体'
        },
        {
          prop: 'isSupportAftersales',
          label: '是否支持售后'
        },
        {
          prop: 'isOpen',
          label: '商家状态'
        },
        {
          prop: 'intime',
          label: '添加时间'
        }
      ],
      storeListIdsSelectTableHeader:[
        {
          prop: 'storeName',
          label: '门店名称'
        },
        {
          prop: 'busName',
          label: '所属商家'
        },
        {
          prop: 'serviceScope',
          label: '服务范围',
          formatter: row => serviceScopeMap[row.serviceScope]
        },
        {
          prop: 'deliveryType',
          label: '物流方式',
          formatter: row => {
            if (row.deliveryType) {
              return row.deliveryType.split(',').map(text => deliveryTypeMap[text]).join('、')
            } else {
              return '无物流'
            }
          }
        },
        {
          prop: 'labelName',
          label: '搜索标签'
        },
        {
          prop: 'storeStatus',
          label: '门店状态',
          formatter: row => storeStatusMap[row.storeStatus]
        },
        {
          prop: 'intimeText',
          label: '添加时间',
          formatter: row => row.intime.slice(0, 16)
        }
      ],
      key: 0,
      ownerSearchParams: {
        userName: undefined,
        mobileNum: undefined,
        communityId: undefined,
        userSource: 1,
        address: '',
        buildNum: '',
        unitNum: '',
        roomNum: '',
        regFromDate: '',
        regToDate: '',
        appCode: ''
      },
      receiveUserSearchParams: {
        userName: '',
        logingName: '',
        phone: '',
        roleType: undefined,
        roleId: undefined,
        organizeId: undefined,
        startIntime: '',
        endIntime: '',
        appCode: ''
      },
      communitySearchParams: {
        communityName: '',
        searchRegionType: undefined,
        regionId: undefined,
        province: '',
        city: '',
        area: '',
        operateRegionId: undefined,
        communityStage: undefined,
        communityType: undefined,
        communityStatus: 0,
        appCode: ''
      },
      buiddingSearchParams: {
        communityId: '',
        blockName: '',
        buildingNumber: '',
        appCode: ''
      },
      orgIdsSearchParams: {
        orgName: '',
        tagId: undefined,
        communityId: undefined,
        houseId: undefined,
        isTop: undefined,
        managerId: undefined,
        topId: undefined,
        source: undefined,
        appCode: ''
      },
      listIdsSearchParams: {
        labelName: '',
        labelType: undefined,
        categoryId: undefined,
        inuser: '',
        status: undefined,
        toDate: '',
        createStartTime: '',
        createEndTime: '',
        joinStartTime: '',
        joinEndTime: ''
      },
      shopListIdsSearchParams:{
        busName:'',
        loginName:'',
        integrationType:undefined,
        integrationName:'',
        legalUnitId:'',
        isSelfsupport:undefined,
        isSupportAftersales:undefined,
        isOpen:undefined,
        startDate:'',
        endDate:'',
      },
      storeListIdsSearchParams:{
        storeName:'',
        busId:'',
        serviceScope:undefined,
        deliveryType:undefined,
        isGrant:undefined,
        labelName:'',
        storeStatus:undefined,
      },
      receiveUserResponseParams: {
        id: 'id',
        name: 'userName'
      },
      ownerResponseParams: {
        id: 'id',
        name: 'userName'
      },
      communityResponseParams: {
        id: 'id',
        name: 'communityName'
      },
      buiddingResponseParams: {
        id: 'nodeId',
        name: 'displayName'
      },
      orgIdsResponseParams: {
        id: 'id',
        name: 'orgName'
      },
      listIdsResponseParams: {
        id: 'id',
        name: 'labelName'
      },
      ownerResponseKey: {
        id: 'id',
        name: 'text'
      },
      receiveUserResponseKey: {
        id: 'id',
        name: 'text'
      },
      communityResponseKey: {
        id: 'id',
        name: 'text'
      },
      buiddingResponseKey: {
        id: 'id',
        name: 'text'
      },
      orgIdsResponseKey: {
        id: 'id',
        name: 'text'
      },
      listIdsResponseKey: {
        id: 'id',
        name: 'text'
      },
      shopListIdsResponseKey: {
        id: 'id',
        name: 'text'
      },
      storeListIdsResponseKey: {
        id: 'id',
        name: 'text'
      },
      communityExtraParams: {
        ismodal: 1
      },
      orgIdsExtraParams: {
        centerType: 2
      },
      shopListIdsResponseParams:{
        id: 'id',
        name: 'busName'
      },
      storeListIdsResponseParams:{
        id: 'id',
        name: 'storeName'
      },
      userSourceOps: userSourceOps(),
      roleTypeOps: [],
      getOwnerListURL,
      getReceiveUserListURL,
      getCommunityListURL,
      getBuiddingListURL,
      getOrgIdsListURL,
      getListIdsURL,
      categoryOptionsURL,
      getShopListURL,
      getStoreListURL,
      labelLevelOps,
      communityParams,
      roleNameParams: {
        searchUrl: getRoleNameListURL,
        request: {
          text: 'roleName_',
          value: 'roleId_'
        },
        response: {
          text: 'name',
          value: 'id'
        },
        subjoin: {
          roleType: undefined
        }
      },
      organizeParams: {
        searchUrl: getOrganizeURL,
        request: {
          text: 'orgName_',
          value: 'orgId_'
        },
        response: {
          text: 'name',
          value: 'id'
        },
        subjoin: {
          roleType: undefined
        }
      },
      regionIdParams: {
        searchUrl: geAllRegionURL,
        request: {
          text: 'regionName',
          value: 'regionId'
        },
        response: {
          text: 'name',
          value: 'id'
        },
        subjoin: {
          regionType: undefined
        }
      },
      tagIdParams: {
        searchUrl: getOrgTagURL,
        request: {
          text: 'tagName',
          value: 'tagId'
        },
        response: {
          text: 'tagName',
          value: 'id'
        }
      },
      houseIdParams: {
        searchUrl: getUserRoomURL,
        request: {
          text: 'roomNum',
          value: 'houseId'
        },
        response: {
          text: 'name',
          value: 'id'
        },
        method: 'post'
      },
      managerIdParams: {
        searchUrl: superManagerURL,
        request: {
          text: 'keyword',
          value: 'keyword'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      },
      topIdParams: {
        searchUrl: getTopOrgURL,
        request: {
          text: 'orgName',
          value: 'topId'
        },
        response: {
          text: 'orgName',
          value: 'id'
        }
      },
      minDate: new Date(),
      form: {
        noticeTitle: '',
        noticeSummary: '',
        imgUrl: '',
        appType: 1,
        appCode: '',
        skipType: 1,
        noticeContent: '',
        url: '',
        skipData: '',
        sendType: 2,
        sign: 1,
        receiveUserIds: [],
        butlerWorkerIds: [],
        communityIDs: [],
        nodeIds: [],
        orgIds: [],
        sendImmediately: 0,
        pushtime: '',
        superOrgIds: [],
        listIds: [],
        shopListIds:[],
        storeListIds:[]
      },
      btnText: '保存',
      submitBtnDisbaled: false,

      labelStatusOpts: [
        {
          text: '全部',
          value: undefined
        }
      ],

      categoryOpts: [
        {
          text: '全部',
          value: undefined
        }
      ],
      // 商家相关
      datepickerParams: {
        type: 'rangedatetimer',
        format: 'YYYY-MM-DD HH:mm'
      },
      legalUnitIdParams: {
        searchUrl: getLegalUnitIdURl,
        request: {
          text: 'legalUnitName',
          value: 'legalUnitId'
        }
      },
      integrationTypeOps: integrationTypeOps,
      isSelfsupportOps: isSelfsupportOps,
      isSupportAftersalesOps: isSupportAftersalesOps,
      isOpenOps: isOpenOps,

      // 门店相关
      busParams: {
        searchUrl: getShopURL,
        request: {
          text: 'busName',
          value: 'busId'
        }
      },
      isGrantOps: setIsGrantOps(1),
      serviceScopeOps,
      deliveryTypeOps,
      storeStatusOps,
      businessAppTypeOps:[
        {
          text: '用户端App',
          value: 1
        },
        {
          text:'商家端App',
          value:3
        }
      ],
      gridTypeOps:[
        {
          text: '用户端App',
          value: 1
        },
        {
          text: '管理端App',
          value: 2
        }
      ]
    }
  },

  computed: {
    subjoin () {
      return {
        appCode: this.form.appCode
      }
    },
    showOrgName () {
      return this.$store.state.baseConfig.docConfig.ORG_NAME || ''
    },
    appCodeParams () {
      return {
        searchUrl: `${APPURL}${this.form.appType}`,
        request: {
          text: 'appName',
          value: 'id'
        },
        response: {
          text: 'appName'
        }
      }
    },
    appCode () {
      return this.form.appCode
    },
    ownerExtraParams () {
      return {
        isGridNotice: this.noticeType === 21 ? '1' : ''
      }
    },
    receiveUserExtraParams () {
      return {
        isGridNotice: this.noticeType === 21 ? '1' : '',
        status: 1
      }
    }
  },
  watch: {
    appCode () {
      this.appCodeChange()
    }
  },
  created () {
    const userType = String(this.$store.state.userInfo.userType)
    this.userType = Number(userType)
    this.getRoleType()
    this.getCommunityStage()
    this.getCommunityType()
  },
  mounted () {
    const { noticeType } = this.$route.query
    this.noticeType = Number(noticeType)
    this.form.noticeType = this.noticeType
    if (this.noticeType === 5) {
      this.$setBreadcrumb('新增商业通知')
    } else if (this.noticeType === 6) {
      this.$setBreadcrumb('新增项目通知')
    } else if (this.noticeType === 21) {
      this.$setBreadcrumb('新增网格通知')
      this.form.sendType = 1
    } else if (this.noticeType === 23) {
      this.$setBreadcrumb('新增商家通知')
      this.form.appType = 3
      this.form.sendType = 8
    }
    this.setSendTypeOps()
    this.setSkipTypeOps()
  },
  methods: {
    async goBack () {
      let isOk = await this.$confirm(
        '您当前输入信息还未被保存，确定离开当前页面吗？'
      )
      if (isOk) {
        this.$router.go(-1)
      }
    },
    searchRegionTypeChange (val, item) {
      this.$refs.regionName.clearValue()
      this.regionIdParams.subjoin.regionType = val
    },
    roleTypeChange (val, item) {
      this.$refs.roleName.clearValue()
      this.$refs.organizeName.clearValue()
      this.roleNameParams.subjoin.roleType = val
      this.organizeParams.subjoin.roleType = val
    },
    getRoleType () {
      this.$axios.get(getRoleTypeURL, {}).then(async (res) => {
        if (res.status === '100') {
          let data = res.data
          let list = []
          data.forEach((v, i, a) => {
            list.push({
              text: v.name,
              value: v.id
            })
          })
          if (!this.receiveUserSearchParams.roleType && list.length) {
            this.receiveUserSearchParams.roleType = list[0].value
            this.roleNameParams.subjoin.roleType = this.receiveUserSearchParams.roleType
            this.organizeParams.subjoin.roleType = this.receiveUserSearchParams.roleType
          }
          this.roleTypeOps = list
        }
      })
    },
    getCommunityStage () {
      this.$axios.get(getCommunityStageURL, {}).then(async (res) => {
        if (res.status === '100') {
          let data = res.data
          let list = [
            {
              text: '全部',
              value: null
            }
          ]
          data.forEach((v, i, a) => {
            list.push({
              text: v.value,
              value: v.id
            })
          })
          this.communityStageOps = list
        }
      })
    },
    getCommunityType () {
      this.$axios.get(getCommunityTypeURL, {}).then(async (res) => {
        if (res.status === '100') {
          let data = res.data
          let list = [
            {
              text: '全部',
              value: null
            }
          ]
          data.forEach((v, i, a) => {
            list.push({
              text: v.value,
              value: v.id
            })
          })
          this.communityTypeOps = list
        }
      })
    },
    async submitBefore (data) {
      if (!(await this.checkSkipCommunity())) {
        return false
      }
      let receiveUserIdList = []
      let butlerWorkerList = []
      let communityIdList = []
      let nodeIdList = []
      let orgIdList = []
      let skipUrl = {
        pageId: '40000',
        pageType: 4,
        url: data.url,
        H5NeedAuthorize: false,
        detailId: ''
      }
      if(data.appType === 2){
        if(data.noticeType === 21){
          data.noticeType = 22
        }else{
          data.noticeType = 10
        }
      }
      // 如果是商家端APP
      if(data.appType === 3){
         data.noticeType = 23
      }
      let formSubmitData = {
        noticeTitle: data.noticeTitle,
        noticeSummary: data.noticeSummary,
        imgUrl: data.imgUrl,
        // noticeType:
        //   data.appType === 1
        //     ? data.noticeType
        //     : data.noticeType === 21
        //       ? 22
        //       : 10,
        noticeType:data.noticeType,
        skipType: data.skipType,
        sendType: data.sendType === 'sameAs1' ? 1 : data.sendType,
        appType: data.appType,
        dataObject: data.noticeTitle,
        orgName: this.$store.state.userInfo.userOrganization,
        noticeContent: '',
        sendImmediately: data.sendImmediately
      }
      if (data.sendImmediately === 0) {
        formSubmitData['pushtime'] = data.pushtime
      }
      if (
        (data.sendType === 1 || data.sendType === 'sameAs1') &&
        data.appType === 1
      ) {
        data.receiveUserIds.forEach((item) => {
          receiveUserIdList.push(item.id)
        })
        formSubmitData['receiveUser'] = JSON.stringify(receiveUserIdList)
      }

      if (data.sendType === 1 && data.appType === 2) {
        data.butlerWorkerIds.forEach((item) => {
          butlerWorkerList.push(item.id)
        })
        formSubmitData['receiveUser'] = JSON.stringify(butlerWorkerList)
      }
      if (data.sendType === 2 || data.sendType === 50) {
        data.communityIDs.forEach((item) => {
          communityIdList.push(item.id)
        })
        formSubmitData['communityIDs'] = communityIdList
      }
      if (data.sendType === 4) {
        data.nodeIds.forEach((item) => {
          nodeIdList.push(item.id)
        })
        formSubmitData['nodeIds'] = nodeIdList
      }
      if (data.sendType === 5) {
        data.orgIds.forEach((item) => {
          orgIdList.push(item.id)
        })
        formSubmitData['orgIds'] = orgIdList
      }
      if (data.sendType === 6) {
        data.superOrgIds.forEach((item) => {
          orgIdList.push(item.id)
        })
        formSubmitData['orgIds'] = orgIdList
      }
      // 用户标签
      if (data.sendType === 7) {
        const labelList = []
        data.listIds.forEach(item => {
          labelList.push(item.id)
        })
        formSubmitData['labelIds'] = labelList
      }
      if (data.skipType === 1) {
        formSubmitData['noticeContent'] = data.noticeContent
        if (data.noticeType !== 5 && data.sendType !== 3) {
          formSubmitData['sign'] = data.sign
        }
      } else {
        formSubmitData['skipData'] =
          data.skipType === 3 ? JSON.stringify(skipUrl) : data.skipData
      }
      if (data.sendType === 8) {
        let shopListIds = []
        data.shopListIds.forEach((item) => {
          shopListIds.push(item.id)
        })
        formSubmitData['shopListIds'] = shopListIds
      }
      if (data.sendType === 9) {
        let storeListIds = []
        data.storeListIds.forEach((item) => {
          storeListIds.push(item.id)
        })
        formSubmitData['storeListIds'] = storeListIds
      }
      return formSubmitData
    },
    skipTypeOpsChange (index, pureCheckedOptions, checkedOptions) {
      this.form.skipType = pureCheckedOptions
      this.clearFormMultiIds()
    },
    communityIdChange (list, ids) {
      this.checkParam.communityList = list.map((item) => {
        return {
          communityId: item.id,
          communityName: item.text
        }
      })
      this.checkSkipCommunity()
    },
    // 校验跳转内容是否覆盖项目
    async checkSkipCommunity () {
      if (
        (this.form.sendType === 2 || this.form.sendType === 4) &&
        this.checkParam.communityList.length
      ) {
        let params = {}
        let communityIds = []
        let coverTextObj = {
          2: '项目',
          1: '个人',
          3: '全体用户',
          4: '楼幢',
          5: `${this.showOrgName}`,
          sameAs1: '手机号'
        }
        if (
          this.form.appType === 1 &&
          (this.form.sendType === 1 || this.form.sendType === 'sameAs1')
        ) {
          communityIds = this.form.receiveUserIds.map((item) => {
            return {
              communityId: item.id,
              communityName: item.text
            }
          })
        } else if (this.form.appType === 2 && this.form.sendType === 1) {
          communityIds = this.form.butlerWorkerIds.map((item) => {
            return {
              communityId: item.id,
              communityName: item.text
            }
          })
        } else if (this.form.sendType === 2 || this.form.sendType === 50) {
          communityIds = this.form.communityIDs.map((item) => {
            return {
              communityId: item.id,
              communityName: item.text
            }
          })
        } else if (this.form.sendType === 4) {
          communityIds = this.form.nodeIds.map((item) => {
            return {
              communityId: item.id,
              communityName: item.text
            }
          })
        } else if (this.form.sendType === 5) {
          communityIds = this.form.orgIds.map((item) => {
            return {
              communityId: item.id,
              communityName: item.text
            }
          })
        }
        if (this.form.skipType === 1) {
          let div = document.createElement('div')
          div.innerHTML = this.form.noticeContent
          let jumpResultList = []
          let dom = div.getElementsByClassName('jumpResult')
          for (let i = 0; i < dom.length; i++) {
            jumpResultList.push({
              pageId: dom[i].attributes.pageid.nodeValue,
              detailId: dom[i].attributes.detailid.nodeValue
            })
          }
          params = {
            communityList: communityIds,
            storeIdList: [],
            socialActivityIdList: [],
            secKillIdList: [],
            knowledgeInfoIdList: [],
            discountTopicId: [],
            groupIdList: [],
            productIdList: [],
            questionIdList: [],
            questionNaireIdList: [],
            selfRuleIdList: [],
            subIdList: [],
            tableIdList: [],
            workRoomIdList: []
          }
          jumpResultList.forEach((item) => {
            let isAdd = false
            Object.keys(jumpGoCode).forEach((items) => {
              if (item.pageId === jumpGoCode[items]) {
                if (item.pageType !== 3) {
                  // 3是应用，放在subIdList
                  isAdd = true
                  params[jumpGoCodeMap[items]].push(item.detailId)
                }
              }
            })
            !isAdd && params.subIdList.push(item.detailId)
          })
        } else if (this.form.skipType === 2) {
          params = {
            communityList: communityIds,
            ...this.checkParam2
          }
        }
        let res = await this.$axios.post(checkSkipCommunityURL, params)
        if (res.status === 100) {
          if (res.data && res.data.length > 0) {
            let tempList = []
            res.data.forEach((item, index) => {
              tempList.push(item.communityName)
            })
            let message = `跳转内容无法覆盖${
              coverTextObj[this.form.sendType] || ''
            }【${tempList.join('、')}】发布之后这些${
              coverTextObj[this.form.sendType] || ''
            }点击将无法实现跳转，请确认是否发布`
            let isOk = await this.$confirm(message, { title: '提示' })
            if (!isOk) {
              // 不同意，就要清空所选的园区
              this.clearFormMultiIds()
              return false
            }
            return isOk
          } else {
            return true
          }
        }
      } else {
        return true
      }
    },
    clearFormMultiIds () {
      // this.form.receiveUserIds = []
      // this.form.butlerWorkerIds = []
      // this.form.communityIDs = []
      // this.form.nodeIds = []
      // this.form.orgIds = []
      // setTimeout(() => {
      //   this.$refs.noticeForm.clearValidate()
      // }, 0)
    },
    // 处理跳转内容参数，以便校验跳转的项目覆盖
    dealWithSelectJump (skipdata) {
      // TOOD 处理选中的参数，选择对应的类型加入checkParam
      let isAdd = false
      Object.keys(jumpGoCode).forEach((item) => {
        if (skipdata.pageId === jumpGoCode[item]) {
          if (skipdata.pageType !== 3) {
            // 3是应用，放在subIdList
            isAdd = true
            this.checkParam[jumpGoCodeMap[item]].push(skipdata.detailId)
          }
        }
      })
      !isAdd && this.checkParam.subIdList.push(skipdata.detailId)
      // if (this.form.communityIds.length > 0) {
      //   this.checkSkipCommunity()
      // }
    },
    jumpCallback (skipdata, css, html) {
      if (this.form.noticeContent.indexOf('jumpResult') === -1) {
        this.form.noticeContent = `${this.form.noticeContent}${css}${html}`
      } else {
        this.form.noticeContent = `${this.form.noticeContent}${html}`
      }
      // 处理选中的参数，选择对应的类型加入checkParam
      this.dealWithSelectJump(skipdata)
    },
    jumpFunCallback (skipdata, name) {
      if (!name) {
        return
      }
      this.$refs.noticeForm.clearValidate('skipData')
      this.jumpName = name
      this.form.skipData = JSON.stringify(skipdata)
      let checkParam = {}
      let isAdd = false
      Object.keys(jumpGoCode).forEach((item) => {
        if (skipdata.pageId === jumpGoCode[item]) {
          if (skipdata.pageType !== 3) {
            // 3是应用，放在subIdList
            isAdd = true
            checkParam[jumpGoCodeMap[item]] = [skipdata.detailId]
          }
        }
      })
      !isAdd && (checkParam.subIdList = [skipdata.detailId])
      this.checkParam2 = checkParam
      // 处理选中的参数，选择对应的类型加入checkParam
      // this.dealWithSelectJump(skipdata)
    },
    sendTypeChange (index, pureCheckedOptions, checkedOptions) {
      this.form.sendType = pureCheckedOptions
      this.clearFormMultiIds()
    },
    toSelectOwner () {
      // this.key += 1
      // this.ownerSearchParams.appCode = this.form.appCode
      this.selectOwnerShow = true
    },
    toSelectbutlerWorker () {
      // this.key += 1
      // this.receiveUserSearchParams.appCode = this.form.appCode
      this.selectReceiveUserShow = true
    },
    toSelectcommunity () {
      // this.key += 1
      // this.communitySearchParams.appCode = this.form.appCode
      this.selectCommunityShow = true
    },
    toSelectBuidding () {
      // this.key += 1
      // this.buiddingSearchParams.appCode = this.form.appCode
      this.selectBuiddingShow = true
    },
    toSelectOrgIds () {
      // this.key += 1
      // this.orgIdsSearchParams.appCode = this.form.appCode
      this.selectOrgIdsShow = true
    },
    toSelectSuperOrgIds () {
      // this.key += 1
      // this.orgIdsSearchParams.appCode = this.form.appCode
      this.selectSuperOrgIdsShow = true
    },
    toSelectListIds () {
      this.selectListIdsShow = true
      // 一级分类传0
      this.getCategory(0)
    },
    toSelectShopListIds(){
      this.selectShopListIdsShow = true
    },
    toSelectStoreListIds(){
      this.selectStoreListIdsShow = true
    },
    appCodeChange () {
      this.key += 10
      const paramsNames = [
        'ownerSearchParams',
        'receiveUserSearchParams',
        'communitySearchParams',
        'buiddingSearchParams',
        'orgIdsSearchParams',
        'orgIdsSearchParams'
      ]
      const idsNames = [
        'receiveUserIds',
        'butlerWorkerIds',
        'communityIDs',
        'nodeIds',
        'orgIds',
        'superOrgIds'
      ]
      paramsNames.forEach(k => {
        this[k].appCode = this.form.appCode
      })
      idsNames.forEach(k => {
        this.form[k] = []
      })
      const _this = this
      this.$nextTick(() => {
        idsNames.forEach(k => {
          _this.$refs.noticeForm.clearValidate(k)
        })
      })
    },
    appTypeChange () {
      let type = this.form.appType
      if (type === 1) {
        this.form.sendType = 1
      } else {
        if (this.noticeType === 21) {
          this.form.sendType = 1
        } else {
          this.form.sendType = 2
        }
      }
      if(type === 3){
        this.form.sendType = 8
        this.noticeType = 23
      }
      this.form.skipType = 1
      this.setAppCode()
      this.setSendTypeOps()
      this.setSkipTypeOps()
    },
    setAppCode () {
      if (this.form.appCode === '') {
        this.appCodeChange()
      } else {
        this.form.appCode = ''
      }
      this.$refs.select.clearValue()
    },
    setSkipTypeOps () {
      if (this.form.appType === 1) {
        this.skipTypeOps = [
          {
            checked: true,
            label: '至详情页',
            value: 1
          },
          {
            checked: false,
            label: '至功能页',
            value: 2
          },
          {
            checked: false,
            label: '至外部链接',
            value: 3
          }
        ]
      } else {
        this.skipTypeOps = [
          {
            checked: true,
            label: '至详情页',
            value: 1
          },
          {
            checked: false,
            label: '至外部链接',
            value: 3
          }
        ]
      }
    },
    setSendTypeOps () {
      if (this.form.appType === 1) {
        if (this.userType === 100 || this.userType === 106) {
          if (this.noticeType === 5) {
            this.sendTypeOps = [
              {
                checked: true,
                label: '项目',
                value: 2
              },
              {
                checked: false,
                label: '个人',
                value: 1
              },
              {
                checked: false,
                label: '全体用户',
                value: 3
              },
              {
                checked: false,
                label: '楼幢',
                value: 4
              },
              {
                checked: false,
                label: `${this.showOrgName}`,
                value: 5
              },
              {
                checked: false,
                label: `${this.showOrgName}超管`,
                value: 6
              }
            ]
          } else if (this.noticeType === 6) {
            this.sendTypeOps = [
              {
                checked: true,
                label: '项目',
                value: 2
              },
              {
                checked: false,
                label: '个人',
                value: 1
              },
              {
                checked: false,
                label: '全体用户',
                value: 3
              },
              {
                checked: false,
                label: '楼幢',
                value: 4
              },
              {
                checked: false,
                label: `${this.showOrgName}`,
                value: 5
              },
              {
                checked: false,
                label: `${this.showOrgName}超管`,
                value: 6
              },
              {
                checked: false,
                label: '用户标签',
                value: 7
              }
            ]
          } else if (this.noticeType === 21) {
            this.sendTypeOps = [
              {
                checked: true,
                label: '个人',
                value: 1
              },
              {
                checked: false,
                label: '全体用户',
                value: 3
              },
              {
                checked: false,
                label: '楼幢',
                value: 4
              }
            ]
          }
        } else {
          if (this.noticeType === 5) {
            this.sendTypeOps = [
              {
                checked: true,
                label: '项目',
                value: 2
              },
              {
                checked: false,
                label: '个人',
                value: 1
              },
              {
                checked: false,
                label: '楼幢',
                value: 4
              },
              {
                checked: false,
                label: `${this.showOrgName}`,
                value: 5
              },
              {
                checked: false,
                label: `${this.showOrgName}超管`,
                value: 6
              }
            ]
          } else if (this.noticeType === 6) {
            this.sendTypeOps = [
              {
                checked: true,
                label: '项目',
                value: 2
              },
              {
                checked: false,
                label: '个人',
                value: 1
              },
              {
                checked: false,
                label: '楼幢',
                value: 4
              },
              {
                checked: false,
                label: `${this.showOrgName}`,
                value: 5
              },
              {
                checked: false,
                label: `${this.showOrgName}超管`,
                value: 6
              },
              {
                cchecked: false,
                label: '用户标签',
                value: 7
              }
            ]
          } else if (this.noticeType === 21) {
            this.sendTypeOps = [
              {
                checked: true,
                label: '个人',
                value: 1
              },
              {
                checked: false,
                label: '全体用户',
                value: 3
              },
              {
                checked: false,
                label: '楼幢',
                value: 4
              }
            ]
          }
        }
        this.sendTypeOps.push({
          label: '项目内企业管理员',
          value: 50,
          checked: false
        })
        if (this.noticeType != 21) {
          if (this.sendTypeOps.find((item) => item.value === 1)) {
            this.sendTypeOps.push({
              label: '手机号',
              value: 'sameAs1'
            })
          }
        }
      } else {
        if (this.noticeType === 21) {
          this.sendTypeOps = [
            {
              checked: true,
              label: '个人',
              value: 1
            }
          ]
        } else {
          this.sendTypeOps = [
            {
              checked: true,
              label: '项目',
              value: 2
            },
            {
              checked: false,
              label: '个人',
              value: 1
            }
          ]
        }
      }
      if(this.form.appType === 3){
        this.sendTypeOps = [
          {
            checked: true,
            label: '商家',
            value: 8
          },
          {
            checked: false,
            label: '门店',
            value: 9
          }
        ]
      }
      this.sendTypePermission()
    },
    sendTypePermission () {
      let permissionInfo = this.$store.state.permission
      let updatePerList = this.sendTypeOps.filter((e) => {
        let permission = this.sendTypeMapPermission.get(e.label)
        return permissionInfo[permission]
      })
      this.sendTypeOps = updatePerList
    },
    getCategory (id, opts) {
      // 获取分类
      let self = this
      let params = { params: { parentId: id } }
      this.$axios.get(categoryOptionsURL, params).then(res => {
        if (res.status === 100) {
          let categoryList = res.data || []
          let tempList = [
            {
              text: '全部',
              value: undefined
            }
          ]
          if (categoryList.length) {
            categoryList.forEach(item => {
              tempList.push({
                text: item.categoryName,
                value: item.id
              })
            })
          }
          if (id === 0) {
            self.labelStatusOpts = tempList
            if (self.listIdsSearchParams.labelType) {
              self.getCategory(self.listIdsSearchParams.labelType)
            }
          } else {
            self.categoryOpts = tempList
          }
        }
      })
    },
    labelTypeChange (newValue) {
      this.listIdsSearchParams.categoryId = undefined
      if (newValue) {
        this.getCategory(newValue)
      } else {
        this.categoryOpts = [
          {
            text: '全部',
            value: undefined
          }
        ]
      }
    },
    success () {}
  }
}
</script>
<style lang="scss" scoped>
.el-form-div-wrapper {
  width: 100%;
  display: inline-block;

  .choose-jump-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    width: 750px;
  }
}
.content-wrapper {
  display: flex;
  justify-content: flex-start;

  .right-tip {
    margin-left: 15px;
  }
}
.preview-content {
  padding: 0 10px;
  .preview-title {
    font-size: 22px;
    margin: 5px 0 0px;
    color: #404040;
    line-height: 34px;
    word-break: break-all;
  }
  .preview-time {
    font-size: 12px;
    color: #aaaaaa;
    line-height: 20px;
  }
}
.preview-con ::v-deep img {
  max-width: 100%;
}
</style>
