// 房号类型
const roomTypeOps = [
  {
    text: '未关联房号',
    value: 0
  },{
    text: '当前房号',
    value: 1
  }, {
    text: '全部房号',
    value: 2
  }
]
export {
  roomTypeOps
}
